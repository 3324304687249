import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { defaultCurrency, formatCurrency } from '../../../helpers';
import { useLocale } from '../../../hooks';
import { LocaleDateFormats, localizeHelpers } from '../../../localizeHelpers';
import Button from '../../Button/Button';
import Portrait from '../../Portrait/Portrait';
import FocusAreas from '../../shared/FocusAreas/FocusAreas';
import './SpotlightTile.scss';
import { ReactComponent as SpotlightStar } from '../../../assets/spotlight-star.svg';
import {
  IEmbeddedGroup,
  IMyAvailableDonationMatchingProgram,
  ISpotlightCause,
  ISpotlightEvent,
  ISpotlightReturn,
} from '@gigit/interfaces';
import useToastDispatcher from '../../../hooks/useToaster';
import useBestColor from 'use-best-color';

const generateStarList = Array.from({ length: 9 }, (x, i) => i + 1);
type SpotlightModes = {
  matching: 'matching';
  entity: 'entity';
  event: 'event';
};
interface ISpotlightProps {
  spotlightItem: ISpotlightReturn;
  /** Specifies which type of spotlight template to render */
  mode: keyof SpotlightModes;
  /** Used for CTA on spotlight */
  buttonAction?: {
    onAction: (id: string) => void;
    actionText: string;
  };
  /** Causes Eligible for donation matching */
  causes?: IEmbeddedGroup[];
}

// TODO: update types after mege w/ backend
export default function SpotlightTile(props: ISpotlightProps) {
  const locale = useLocale();
  const history = useHistory();
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const { dispatchToastError } = useToastDispatcher();

  const [ref, bestColor] = useBestColor(['#fff', '#3A3A3A']);

  const renderFromMode = () => {
    switch (props.mode) {
      case 'entity':
        return renderEntitySpotlight(props.spotlightItem.object as ISpotlightCause);
      case 'event':
        return renderEventSpotlight(props.spotlightItem.object as ISpotlightEvent);
      case 'matching':
        return renderMatchingSpotlight(
          props.spotlightItem.object as IMyAvailableDonationMatchingProgram,
        );
      default:
        return renderEmptySpotlight();
    }
  };

  const renderEntitySpotlight = (entity: ISpotlightCause) => {
    const areas = entity?.focus_areas ?? [];
    const handle = (props.spotlightItem.object as ISpotlightCause).handle;
    return (
      <div className={`spotlight-container ${props.mode}`}>
        <div className="spotlight-entity">
          <div className="spotlight-entity-avatar">
            <Portrait
              onClick={() => history.push(`/group/${handle}`)}
              source={entity?.profile_image_url}
              size={128}
            />
          </div>
          <div
            className="spotlight-entity-info"
            notranslate="yes"
          >
            <div
              onClick={() => history.push(`/group/${handle}`)}
              style={{ color: bestColor.color }}
            >
              {' '}
              {entity?.title}{' '}
            </div>
            <div style={{ color: bestColor.color }}>
              {' '}
              Charity ID: <var data-var="charity_id"> {entity?.charity_id} </var>
            </div>
            <FocusAreas focusAreas={areas} />
          </div>
        </div>
        <div className="cause-donate">
          {(props.spotlightItem.object as ISpotlightCause).accepting_donations && (
            <Button
              text={'Donate'}
              onClick={() => history.push(`/group/${handle}/donate`)}
            />
          )}
        </div>
      </div>
    );
  };

  const renderEventSpotlight = (entity: any) => {
    const handle = (props.spotlightItem.object as ISpotlightEvent).handle;
    return (
      <div className={`spotlight-container ${props.mode}`}>
        <div className="spotlight-by">
          <div
            className="program-name"
            style={{ color: bestColor.color }}
            notranslate="yes"
            onClick={() => history.push(`/event/${handle}`)}
          >
            {entity?.title}
          </div>
          <div className="by">
            <Portrait
              onClick={() => {
                history.push(`/company/${props.spotlightItem.owner.handle}`);
              }}
              source={entity?.profile_image_url}
              size={24}
            />
            <p style={{ color: bestColor.color }}>
              Spotlight by{' '}
              <span
                notranslate="yes"
                onClick={() => {
                  history.push(`/company/${props.spotlightItem.owner.handle}`);
                }}
                style={{ color: bestColor.color }}
              >
                {props.spotlightItem.owner.title}
              </span>
            </p>
          </div>
        </div>
        <div className="spotlight-info">
          <div className="program-detail">
            <div
              className="detail-wrap"
              style={{ color: bestColor.color }}
            >
              <p style={{ color: bestColor.color }}>Start Date</p>
              <p style={{ color: bestColor.color }}>
                {localizeHelpers.formatDate(
                  entity?.start_date,
                  LocaleDateFormats.L,
                  locale.currentLocale,
                )}
              </p>
            </div>
            <div
              className="detail-wrap"
              style={{ color: bestColor.color }}
            >
              <p style={{ color: bestColor.color }}>End Date</p>
              <p style={{ color: bestColor.color }}>
                {localizeHelpers.formatDate(
                  entity?.end_date,
                  LocaleDateFormats.L,
                  locale.currentLocale,
                )}
              </p>
            </div>
          </div>
          {entity?.show_campaign_progress && (
            <div className="program-progress">
              <div className="progress-stats">
                <p style={{ color: bestColor.color }}>
                  {' '}
                  {formatCurrency(
                    entity?.raised || 0,
                    entity?.currency ?? defaultCurrency,
                    locale.currentLocale,
                  )}{' '}
                  raised{' '}
                </p>
                <p style={{ color: bestColor.color }}>
                  {' '}
                  {formatCurrency(
                    entity?.goal || 0,
                    entity?.currency ?? defaultCurrency,
                    locale.currentLocale,
                  )}{' '}
                  Goal
                </p>
              </div>
              <progress
                max={entity?.goal}
                value={entity?.raised ?? 0}
              ></progress>
            </div>
          )}
          {entity?.accepting_donations && (
            <div className="action-cta">
              <Button
                text={'Donate'}
                onClick={() => history.push(`/event/${entity.handle}/donate`)}
                onMouseEnter={() => setIsHovering(!isHovering)}
                onMouseLeave={() => setIsHovering(!isHovering)}
                style={{
                  background: isHovering
                    ? props.spotlightItem?.owner?.theme?.primary_color
                    : '#fff',
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderMatchingSpotlight = (entity: any) => {
    const spotlightItemId = props.spotlightItem.object_id.toString();
    const navigateToMatchingOwner = () =>
      history.push(`/company/${props.spotlightItem.owner.handle}`);
    return (
      <div className={`spotlight-container ${props.mode}`}>
        <div className="spotlight-by">
          <div
            style={{ color: bestColor.color }}
            className="program-name"
            notranslate="yes"
          >
            {entity?.program_name}
          </div>
          <div className="by">
            <Portrait
              onClick={() => {
                navigateToMatchingOwner();
              }}
              source={props.spotlightItem.owner.handle}
              size={24}
            />
            <p style={{ color: bestColor.color }}>
              Spotlight by{' '}
              <span
                notranslate="yes"
                onClick={() => {
                  navigateToMatchingOwner();
                }}
                style={{ color: bestColor.color }}
              >
                {props.spotlightItem.owner.title}
              </span>
            </p>
          </div>
          {props.causes && (
            <div className="avatar-stack">
              {props.causes.map((cause) => {
                return (
                  <Portrait
                    size={36}
                    hideMask={true}
                    key={cause.id}
                    currentImage={cause?.profile_image_url}
                    onClick={() => history.push(`/group/${cause?.handle}`)}
                  />
                );
              })}
            </div>
          )}
        </div>
        <div className="spotlight-info">
          <div className="program-detail">
            <div className="detail-wrap">
              <p style={{ color: bestColor.color }}>Start Date</p>
              <p style={{ color: bestColor.color }}>
                {localizeHelpers.formatDate(
                  entity?.start_date,
                  LocaleDateFormats.L,
                  locale.currentLocale,
                )}
              </p>
            </div>
            <div className="detail-wrap">
              <p style={{ color: bestColor.color }}>End Date</p>
              <p style={{ color: bestColor.color }}>
                {localizeHelpers.formatDate(
                  entity?.end_date,
                  LocaleDateFormats.L,
                  locale.currentLocale,
                )}
              </p>
            </div>
            <div className="detail-wrap">
              <p style={{ color: bestColor.color }}>Ratio</p>
              <p style={{ color: bestColor.color }}>{`${entity?.ratio} : 1`}</p>
            </div>
          </div>
          <div className="program-progress">
            <div className="progress-stats">
              <p style={{ color: bestColor.color }}>
                {' '}
                {formatCurrency(
                  entity?.max_amount -
                    entity?.available_donation_match?.remaining_program_matchable || 0,
                  entity?.currency ?? entity?.account?.currency ?? entity?.available_donation_match?.currency ?? defaultCurrency,
                  locale.currentLocale,
                )}{' '}
                Amt. Consumed{' '}
              </p>
              <p style={{ color: bestColor.color }}>
                {' '}
                {formatCurrency(
                  entity?.max_amount || 0,
                  entity?.currency ?? entity?.account?.currency ?? entity?.available_donation_match?.currency ?? defaultCurrency,
                  locale.currentLocale,
                )}{' '}
                Total Match Available
              </p>
            </div>
            <progress
              max={entity?.max_amount}
              value={
                entity?.max_amount - entity?.available_donation_match?.remaining_program_matchable
              }
            ></progress>
          </div>
          <div className="action-cta">
            <Button
              text={props?.buttonAction?.actionText ?? 'Donate'}
              onClick={() => props.buttonAction?.onAction(spotlightItemId)}
              onMouseEnter={() => setIsHovering(!isHovering)}
              onMouseLeave={() => setIsHovering(!isHovering)}
              style={{
                background: isHovering ? props.spotlightItem?.owner?.theme?.primary_color : '#fff',
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderEmptySpotlight = () => {
    return null;
  };

  const backgroundGradient = `linear-gradient(90deg, ${props?.spotlightItem?.owner?.theme?.primary_color} 0%, ${props?.spotlightItem?.owner?.theme?.secondary_color} 100%)`;
  const type = props.spotlightItem.object_type;
  return (
    /** We use article for proper semantics, '...the article element specifies independent, self-contained content.' (w3) */
    <article
      ref={ref}
      className="SpotlightTile"
      style={{
        background:
          type === 'group'
            ? backgroundGradient
            : `url(${props.spotlightItem.owner.cover_image_url})`,
        backgroundColor: props?.spotlightItem?.owner?.theme?.secondary_color,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {renderFromMode()}
      {type !== 'group' && (
        <div
          className="overlay"
          style={{ background: backgroundGradient, opacity: '.9' }}
        ></div>
      )}
      {type === 'group' &&
        generateStarList.map((star, index) => (
          <SpotlightStar
            key={index}
            className={`star star-${star}`}
          />
        ))}
    </article>
  );
}
